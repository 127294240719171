import { ProductRating } from 'src/components/DesignSystem/Widgets2.0/ProductCard/muiStyles';
import StarSharpIcon from '@mui/icons-material/StarSharp';

export const TestimonialRating = ({ ratingCount, color = '#F9CC43' }) => {
  return (
    <ProductRating
      value={ratingCount}
      sx={{
        color: color,
      }}
      fontSize="20px"
      precision={0.1}
      readOnly
      icon={<StarSharpIcon fontSize="inherit" />}
      emptyIcon={<StarSharpIcon fontSize="inherit" />}
    />
  );
};
