import React, { useEffect, useState } from 'react';
import {
  CARD_ANIMATION_CLASSES,
  DEFAULT_IMAGE,
  IMAGE_TYPES,
  MEDIA_TYPES,
  ORDER_DATA_IDS,
  REMOVE_GLOBAL_STYLE,
} from '../../Constants';
import { ICardProps } from '../../Types';
import { getCartWrapperConfigs } from 'src/components/DesignSystem/Widgets2.0/ProductCard/utils';
import classNames from 'classnames';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { useLayoutTypes } from '../../../../hooks/useLayoutTypes';
import {
  getAuthorImagePositionInfo,
  getStylesAndUtilsFromConfig,
  getWrapperStyles,
} from '../../Utils';
import { GlobalLoader } from 'src/components/WidgetMaker/WidgetDnD/globalLoader';
import { Rating } from './Rating';
import { Review } from './Review';
import { AuthorInfo } from './AuthorInfo';

const Card: React.FC<ICardProps> = (props) => {
  const {
    widgetContextState: { selectedWidgetSessionState, widgets, activeWidgetId },
  } = useWidgetDndContextData();

  const infoWrapperStyles = props.infoWrapperConfig || {};

  const { review, authorMedia, ratingCount } = props.testimonialData;

  const reviewStyles = props.reviewConfig || {};
  const cardWidth = props.oneGridCellGeometry.width;

  const {
    authornameConfig: { ...authornameStyles } = {},
    authorProfileConfig: { ...authorProfileStyles } = {},
  } = props.authorInfoConfig || {};

  const { isLayout6, isLayout5, isLayout4 } = useLayoutTypes({
    listPresentationConfig: props.listPresentationConfig,
  });

  const [cardAnimationClass, setCardAnimationClass] = useState(
    CARD_ANIMATION_CLASSES.BASE + CARD_ANIMATION_CLASSES.INACTIVE
  );

  const shouldAdjustCardPaddingX =
    props.isMobile && props.listPresentationConfig.numColumnsMobile === 1;

  const cardWrapperConfigs = getCartWrapperConfigs(props.listPresentationConfig);
  const showBorder = cardWrapperConfigs.isCardLayout;
  const colorConfig =
    props.listPresentationConfig?.colors?.colorSchemes?.[
      props.listPresentationConfig?.colors?.selectedColorSchemeIndex
    ];

  const authorImagePosition = getAuthorImagePositionInfo({
    pos: props.cardFrameConfig?.authorImagePosition,
    isMobileAndL5: isLayout5 && props.isMobile,
  });

  const activeWidget = widgets?.[activeWidgetId];
  const activeWidgetProps = activeWidget?.props;
  const isSelected =
    activeWidgetProps?.subWidgetIndex === props.index &&
    props?.listPresentationConfig?.type === activeWidgetProps?.listPresentation?.type;

  const showImageLoader = isSelected && selectedWidgetSessionState.isAvatarLoading;

  const {
    headlineStylesFromConfig,
    reviewStylesFromConfig,
    imageWidth,
    isContentCenter,
    textAlignmentStyle,
    showImage,
    showRatings,
  } = getStylesAndUtilsFromConfig({
    isMobile: props?.isMobile,
    cardFrameConfig: props?.cardFrameConfig,
  });

  useEffect(() => {
    if (isLayout4) {
      const { BASE, ACTIVE, INACTIVE } = CARD_ANIMATION_CLASSES;
      setCardAnimationClass(BASE + (props?.isActiveCard ? ACTIVE : INACTIVE));
    }
  }, [isLayout4, props?.isActiveCard]);

  function getImageStyleByType({ isVideo = false }) {
    const style: React.CSSProperties = {};

    if (props.cardFrameConfig.authorImagePosition === 'left') {
      if (isLayout5) {
        style.maxWidth = '300px';
        style.width = `${cardWidth * (40 / 100)}px`;
      } else {
        style.width = `${imageWidth}px`;
        if (isVideo && props.cardFrameConfig.imageShape === IMAGE_TYPES.CIRCLE) {
          style.height = `${imageWidth}px`;
        }
      }
    } else {
      style.width = `${imageWidth}px`;
    }
    return {
      style,
    };
  }

  const wrapperStyling = getWrapperStyles({
    showBorder,
    listPresentationConfig: props?.listPresentationConfig,
    isSelected,
    shouldAdjustCardPaddingX,
  });

  function renderAuthorImage() {
    if (!showImage) return null;
    const isVideo = authorMedia.type === MEDIA_TYPES.VIDEO;
    const styling = getImageStyleByType({ isVideo });
    return (
      <div
        className={classNames(
          `tw-relative tw-flex tw-overflow-hidden`,
          !REMOVE_GLOBAL_STYLE?.includes(props.cardFrameConfig?.imageShape)
            ? imageWidth < 60
              ? 'imgWrapperGlobalStyleMin'
              : 'imgWrapperGlobalStyle'
            : ''
        )}
        style={{ ...styling.style, ...props.imageWrapperStyles }}
      >
        {authorMedia.type !== MEDIA_TYPES.VIDEO ? (
          <img
            className="removeGlobalOverride tw-w-full tw-object-cover"
            src={authorMedia.link || DEFAULT_IMAGE}
          />
        ) : (
          <video
            src={authorMedia.link || DEFAULT_IMAGE}
            autoPlay
            loop
            muted
            style={{ ...styling.style }}
            className="removeGlobalOverride tw-max-w-full tw-object-cover"
          />
        )}

        {showImageLoader && (
          <div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
            <GlobalLoader type="loader1" />
          </div>
        )}
      </div>
    );
  }

  function renderByDataId(id) {
    switch (id) {
      case ORDER_DATA_IDS.RATING:
        return (
          showRatings && (
            <Rating
              isContentCenter={isContentCenter}
              isLayout4={isLayout4}
              cardAnimationClass={cardAnimationClass}
              ratingCount={ratingCount}
              ratingColor={props.cardFrameConfig?.ratingColor}
            />
          )
        );
      case ORDER_DATA_IDS.REVIEW:
        return (
          <Review
            authorImagePosition={authorImagePosition}
            isLayout4={isLayout4}
            cardAnimationClass={cardAnimationClass}
            reviewStyles={reviewStyles}
            testimonialData={props.testimonialData}
            headlineStylesFromConfig={headlineStylesFromConfig}
            reviewStylesFromConfig={reviewStylesFromConfig}
            textAlignmentStyle={textAlignmentStyle}
            isMobile={props.isMobile}
            listPresentationConfig={props.listPresentationConfig}
          />
        );
      case ORDER_DATA_IDS.AUTHOR_INFO:
        return (
          <AuthorInfo
            isContentCenter={isContentCenter}
            authorImagePosition={authorImagePosition}
            isLayout4={isLayout4}
            cardAnimationClass={cardAnimationClass}
            renderAuthorImage={renderAuthorImage}
            showImage={showImage}
            colorConfig={colorConfig}
            textAlignmentStyle={textAlignmentStyle}
            testimonialData={props.testimonialData}
            cardFrameConfig={props.cardFrameConfig}
            authornameStyles={authornameStyles}
            authorProfileStyles={authorProfileStyles}
          />
        );
      default:
        return null;
    }
  }

  function getDataByOrder() {
    return props.dataOrder.map((data) => renderByDataId(data?.id));
  }

  if (!review?.trim()) return null;

  return (
    <div
      className={classNames(
        `tw-relative tw-flex tw-flex-shrink-0 tw-items-start tw-justify-between tw-rounded-[8px]`,
        cardWrapperConfigs.isCardLayout &&
          `tw-py-[24px] ${props.isMobile ? 'tw-px-[14px]' : 'tw-px-[20px]'}`,
        wrapperStyling.className
      )}
      style={{
        width: `${cardWidth}px`,
        ...wrapperStyling.style,
      }}
    >
      <div className="tw-flex tw-w-full tw-items-start tw-justify-between tw-gap-[5%]">
        {isSelected && (
          <div className="tw-absolute -tw-left-[1px] -tw-top-[1px] tw-z-50 tw-w-[108px] tw-bg-[#2F80ED] tw-px-[12px] tw-py-[4px] tw-text-center">
            <span className="tw-z-50 tw-text-nowrap tw-text-[14px] tw-font-normal tw-text-[#fff]">{`Testimonial ${activeWidgetProps?.subWidgetIndex + 1}`}</span>
          </div>
        )}
        {authorImagePosition.left && renderAuthorImage()}
        <div
          className={classNames(
            'tw-flex tw-flex-1 tw-flex-col tw-justify-between',
            isContentCenter ? 'tw-items-center' : '',
            authorImagePosition.left ? 'tw-my-auto' : ''
          )}
        >
          {authorImagePosition.top && renderAuthorImage()}
          <div
            className={classNames(
              'tw-items tw-flex tw-flex-1 tw-flex-col tw-items-stretch tw-justify-between tw-overflow-hidden',
              authorImagePosition.top &&
                (isLayout6
                  ? 'tw-mt-[32px] !tw-gap-[20px]'
                  : 'tw-mt-[24px] !tw-gap-[16px]')
            )}
            style={infoWrapperStyles}
          >
            {getDataByOrder()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
