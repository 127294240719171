import classNames from 'classnames';

export const AuthorInfo = ({
  isContentCenter,
  authorImagePosition,
  isLayout4,
  cardAnimationClass,
  renderAuthorImage,
  showImage,
  colorConfig,
  textAlignmentStyle,
  testimonialData,
  cardFrameConfig,
  authornameStyles,
  authorProfileStyles,
}) => {
  const { authorName, authorDesignation } = testimonialData;
  return (
    <div
      className={classNames(
        'tw-flex tw-w-full  tw-gap-[12px]',
        isContentCenter &&
          (authorImagePosition.bottom ? 'tw-items-center' : 'tw-justify-center'),
        authorImagePosition.bottom ? 'tw-flex-col tw-justify-center' : 'tw-items-center',
        isLayout4 ? cardAnimationClass : ''
      )}
    >
      {(authorImagePosition.withAuthorInfo || authorImagePosition.bottom) &&
        renderAuthorImage()}
      <div
        className={classNames(
          'tw-flex tw-flex-col tw-gap-[2px] tw-text-[#050505]',
          isContentCenter && !showImage && 'tw-text-center'
        )}
        style={{
          color: colorConfig.text,
          ...(!authorImagePosition.withAuthorInfo ? textAlignmentStyle : {}),
        }}
      >
        <h5
          className="tw-m-0"
          style={{
            ...authornameStyles,
            color: cardFrameConfig.authorNameColor,
          }}
        >
          {authorName || 'Anonymous'}
        </h5>
        {!!authorDesignation && (
          <p
            className="tw-m-0 tw-text-[12px]"
            style={{
              ...authorProfileStyles,
              color: cardFrameConfig.designationColor,
            }}
          >
            {authorDesignation}
          </p>
        )}
      </div>
    </div>
  );
};
