import classNames from 'classnames';
import { TestimonialRating } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/TestimonialsBuilder/TestimonialRating';

export const Rating = ({
  isContentCenter,
  isLayout4,
  cardAnimationClass,
  ratingCount,
  ratingColor,
}) => {
  return (
    <div
      className={classNames(
        'tw-flex tw-w-full',
        isContentCenter ? 'tw-justify-center' : '',
        isLayout4 ? cardAnimationClass : ''
      )}
    >
      <TestimonialRating
        ratingCount={ratingCount || 5}
        color={ratingColor || '#F9CC43'}
      />
    </div>
  );
};
