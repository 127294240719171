import { twMerge } from 'tailwind-merge';
import Card from './Components/Card';
import { CARD_DEFAULT_CONFIGS } from './Constants';
import style from '../../ListPresentation.module.scss';
import {
  getActiveTestimonialIndex,
  getImageWrapperStyles,
  getViewableTestimonials,
} from './Utils';
import { useAutoScrollOrRotation } from '../../hooks/useAutoScrollOrRotation';
import { LAYOUT_TYPE } from 'src/containers/AdditionalPage/constant';

const Testimonials = ({
  listPresentationConfig,
  cardFrameConfig,
  oneGridCellGeometry,
  isBuilder,
  isMobile,
  itemCountTotal,
  gridGapAxis,
  sectionContainerRef,
  carouselIndicatorConfigs,
  containerTracks,
  isTestimonialListingPage,
}) => {
  const isCarousel = listPresentationConfig?.layoutType === LAYOUT_TYPE.CAROUSEL;
  const dataOrder = cardFrameConfig?.childElementOrder || [];

  const testimonialsData = getViewableTestimonials({
    cardFrameConfig,
    isCarousel,
    isTestimonialListingPage,
    containerTracks,
  });

  const totalItems = testimonialsData?.length;
  const config = CARD_DEFAULT_CONFIGS[listPresentationConfig?.layoutName];

  const { isAutoScrollEnabled, autoScrollTimer, isAutoRotateEnabled, autoRotateTimer } =
    useAutoScrollOrRotation({ listPresentationConfig });

  const columns = containerTracks.numColumns;

  const showAutoRotation =
    isCarousel && isAutoRotateEnabled && autoRotateTimer && totalItems > columns;

  const effectiveTestimonialsData = showAutoRotation
    ? testimonialsData.concat(testimonialsData.slice(0, columns))
    : testimonialsData;

  // used only in case of layout-4
  const activeTestimonialIdx = getActiveTestimonialIndex({
    carouselIndicatorConfigs,
    totalItems,
  });

  const allTestimonialCards = effectiveTestimonialsData.map((testimonialData, index) => {
    const testimonialIndex = index % totalItems;
    return (
      <Card
        key={testimonialData?.id || index}
        dataOrder={dataOrder}
        testimonialData={testimonialData}
        listPresentationConfig={listPresentationConfig}
        cardFrameConfig={cardFrameConfig}
        oneGridCellGeometry={oneGridCellGeometry}
        isBuilder={isBuilder}
        isMobile={isMobile}
        index={testimonialIndex}
        isActiveCard={testimonialIndex === activeTestimonialIdx}
        imageWrapperStyles={getImageWrapperStyles(
          cardFrameConfig?.imageShape,
          testimonialIndex
        )}
        {...config}
      />
    );
  });

  if (isCarousel && isAutoScrollEnabled) {
    const groupWidth =
      oneGridCellGeometry.width * itemCountTotal + itemCountTotal * gridGapAxis.columnGap;
    const testimonialCardWithWrapper = (
      <div
        style={{
          gap: gridGapAxis.columnGap,
          maxWidth: groupWidth,
          width: groupWidth,
          paddingRight: gridGapAxis.columnGap,
          animationDuration: autoScrollTimer * Math.ceil(totalItems / columns) + 's',
        }}
        className={style.group}
      >
        {allTestimonialCards}
      </div>
    );

    return (
      <div
        style={{
          maxWidth: sectionContainerRef.current?.clientWidth,
        }}
        className={twMerge(style.carousel, 'tw-pb-[10px] tw-pt-[35px]')}
      >
        {testimonialCardWithWrapper}
        {testimonialCardWithWrapper}
        {testimonialCardWithWrapper}
      </div>
    );
  }

  return <>{allTestimonialCards}</>;
};

export default Testimonials;
