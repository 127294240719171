export const ADDITIONAL_PAGE_TYPE = {
    BLOG: 'blog',
    NEW_BLOG: 'NewBlog',
    TESTIMONIAL: 'testimonial',
    HOME: 'home',
    NEW_TESTIMONIALS: 'Testimonials'
}

export const LAYOUT_TYPE = {
    GRID: 'grid',
    CAROUSEL: 'carousel',
}